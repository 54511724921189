/* общий стиль feed ленты */
/* ---------------------------------------------------------- */


// Import colors, fonts etc...
@import "partials/variables";

body
{
	background-color: #f4f8fb;
}

header
{
	position: -webkit-sticky; //для Safari
	position: sticky; //для Google Chrome
	top: 0; // приклейка шапки
	z-index: 1020;
	-webkit-backdrop-filter: saturate(180%) blur(20px);
	-webkit-transition:background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
	transition:background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
	background-color: rgba(255, 255, 255, 0.9);
		-webkit-transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
		transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
		-webkit-transition-property: background-color, -webkit-backdrop-filter;
		transition-property: background-color, -webkit-backdrop-filter;
		transition-property: background-color, backdrop-filter;
		transition-property: background-color, backdrop-filter, -webkit-backdrop-filter;
	border-radius: 2px;
	height: 100%;
	margin: 0px 0px 20px 0px;
}

.wc-container
{
    max-width: 1040px;
    margin: 0 auto;
}
.social-share
{
    margin-left: -20px;
    margin-right: -20px;
}

a {
  
  text-decoration: none;
  color: $base-ssilki;
  }

a:hover
{
    text-decoration: underline;
}

.site-main {
    z-index: 100;
    flex-grow: 1;
}

/* Full width page blocks */
.outer {
    position: relative;
}

/* Centered content container blocks */
.inner {
    margin: 0 auto;
    max-width: 1040px;
    width: 100%;
}

.post-footer .column-full a {
    font-size: 18px;
}

.post-feed {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.post-card {
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 10px 20px;
    min-height: 200px;
    background: #fff center center;
    background-size: cover;
    border-radius: 5px;
    border-bottom: 1px solid #e3e3e3;
}

.post-card a:hover{
    text-decoration: none;
}


.post-card:hover {
    transition: all 0.3s ease;
    transform: translate3D(0, -1px, 0);
    box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.04),-10px 0 20px 0px rgba(0,0,0,0.04); //apple
}

.post-card-image-link {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}

.post-card-image {
    width: auto;
    height: 200px;
    background: var(--lightgrey) no-repeat center center;
    background-size: cover;
}

.post-card-content-link {
    position: relative;
    display: block;
    padding: 25px 25px 0;
}



.post-card-tags {
    display: block;
    margin-bottom: 4px;
    font-size: 13px;
    color: #404040;
    line-height: 1.15em;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.post-card-title {
    margin-bottom: 8px;
    color: #15171a;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: -.1px;
    line-height: 1.4;
}

.post-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.post-card-excerpt {
    font-family: $primary-font; // variables inside partials > _variables.scss
    color: #738a94;
    font-size: 13px;
    line-height: 1.5;
}

.post-card-meta 
		{
		color:#666;
		clear:both;
		font-size:11px;
		line-height:18px;
		padding: 5px 25px 8px;
		margin-left: auto;
		}
.post-card-meta span
		{
		margin-right:4px;
		vertical-align:middle
		}
.post-card-meta a
		{
		font-weight:500
		}    

/* DEVISE UPDATES */

@media (min-width: 900px) {
    .home-template .post-feed,
    .tag-template .post-feed,
    .author-template .post-feed {
        padding-top: 0;
    }
}

@media (min-width: 795px) {
    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) {
        flex: 1 1 100%;
        flex-direction: row;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-image-link {
        position: relative;
        flex: 1 1 auto;
        border-radius: 5px 0 0 5px;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-image {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-content {
        flex: 0 1 357px;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) h2 {

    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) p {

    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-content-link {
        padding: 25px 25px 0;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-meta {
		padding: 0px 25px 8px;
    }
}

.home-template .site-header:after {
    display: none;
}


/* маленький экран 650px */
/* ---------------------------------------------------------- */
@media (max-width: 650px) {
    
    header
    {
        //шапка не приклеивается 
		margin: 0px 0px 20px 0px;
        width: 100%;
    }
    
    .post-card {
    margin: 0 0px 20px;
    }


    .post-card-meta {
    font-size: 12px;
    }
    .post-card-content-link {
    padding: 10px 25px 0;
    }
    .post-card-title {
    margin-bottom: 8px;
    color: #15171a;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: -.1px;
    line-height: 1.4;
	}
	.outer {
    position: relative;
    padding: 0 2vw;
	}
	
    .post-footer .column-full a {
    font-size: 16px;
	}


}


/* верхнее меню */
/* ---------------------------------------------------------- */

.site-nav {
    position: relative;
    z-index: 300;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow-y: hidden;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left:5px;
    padding-right:5px;
}



.site-nav-left {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    margin-right: 10px;
    padding-bottom: 80px;
    white-space: nowrap;

    -ms-overflow-scrolling: touch;
}

.site-nav-logo {
    flex-shrink: 0;
    display: block;
    margin-right: 24px;
    padding: 11px 0;
    color: #fff;
    font-size: 1.7rem;
    line-height: 1em;
    font-weight: bold;
    letter-spacing: -0.5px;
}

.site-nav-logo:hover {
    text-decoration: none;
}

.site-nav-logo img {
    display: block;
    width: auto;
    height: 34px;
}

.nav {
    display: flex;
    margin: 0 0 0 -12px;
    padding: 0;
    list-style: none;
}

.nav li {
    display: block;
    margin: 0;
    padding: 0;
}

.nav li a {
    display: block;
    margin: 0;
    padding: 10px 12px;
    color: #15171a;
    opacity: 0.8;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
}

.nav li a:hover {
    text-decoration: none;
    opacity: 1;
}

.site-nav-right {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 40px;
    padding-top: 15px;
}

.social-links {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.social-links a:last-of-type {
    padding-right: 20px;
}

.social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 10px;
    color: #000;
    opacity: 0.8;
    text-decoration: none;
}

.social-link:hover {
    opacity: 1;
    text-decoration: none;
    border-bottom: 0px;
}

.site-nav-right social-links a:hover{
    text-decoration: none;
}

.social-link svg {
    height: 1.8rem;
    fill: #000;
}

	.subscribe-button {
	display: block;
    padding: 3px 6px;
    border: #0070c9 1px solid;
    color: #000;
    line-height: 1em;
    border-radius: 10px;
    opacity: 0.8;
    text-decoration: none;
    border-bottom-width: 2px;
}

    .subscribe-button:hover {
    text-decoration: none;
    opacity: 1;
    border-bottom: 0px;
    border: #0f8ad3 1px solid;
}


@media (max-width: 700px) {

    .site-nav-left {
        margin-right: 0;
        padding-left: 4vw;
    }
    .site-nav-right {
        display: none;
    }
}


/* Подсветка тегов */

	.tag-instagram
{
    color:#FF31A0;
}
	.tag-live
{
    color:#17a2e1;
}
	.tag-bitcoin
{
    color:#B5BC02;
}
	.tag-telegram
{
    color:#437ffe;
}
	.tag-new
{
    color:#FF2D55;
}
	.tag-internet
{
    color:#FE4E47;
}


/* 		футер страницы */
/* ---------------------------------------------------------- */

.post-footer .column-full a:hover{
    border-bottom: 0px;
}
.site-footer-content a:hover{
    border-bottom: 0px;
}


.site-footer {
    position: relative;
    padding-top: 20px;
    padding-bottom: 40px;
    color: #404040;
    border-top: 1px solid grey;
}

.site-footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 1.4;
    font-family: $primary-font;
}

.site-footer-content ul {
	float: none;
	padding-left: 0px;
}
                
.site-footer-content li:first-child a {
	padding-left: 0;
	border-left: none;
}


.site-footer-content .contacts li:nth-child(2) a {
    border-left: none;
}
				
.site-footer-content li + li a {
	margin-left: .6em;
	padding-left: .6em;
}
				
.site-footer-content ul a {
	border-left: 1px solid #d0d0d0;
}

.site-footer-content .contacts a {
	color: #000;
}

.site-footer-content .contacts a:hover {
	color: #0070c9;
}
                
.site-footer-content li {
	display: inline-block;
}


@media (max-width: 650px) {
    .site-footer-content {
        font-size: 12px;
        line-height: 1.4;
    }
    
	.poweredby {
    display: flex;
}

}

@media (min-width: 650px) and (max-width: 1080px) {
	.site-footer-content {
	    padding: 0 2vw;
}
	
}



/* Окно подписки */
/* ---------------------------------------------------------- */

.subscribe-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,25,40,0.97);
    opacity: 0;
    transition: opacity 200ms ease-in;
    pointer-events: none;

    backdrop-filter: blur(3px);
}

.subscribe-overlay:target {
    opacity: 1;
    pointer-events: auto;
}

.subscribe-overlay-content {
    position: relative;
    z-index: 9999;
    margin: 0 0 5vw 0;
    padding: 4vw;
    color: #fff;
    text-align: center;
}

.subscribe-overlay-content a:hover{
    border-bottom: 0px;
}


.subscribe-overlay-logo {
    position: fixed;
    top: 23px;
    left: 30px;
    height: 30px;
}

.subscribe-overlay-title {
    display: inline-block;
    margin: 0 0 10px 0;
    font-size: 6rem;
    line-height: 1.15em;
}

.subscribe-overlay-description {
    margin: 0 auto 50px;
    max-width: 650px;
    font-family: $primary-font; // variables inside partials > _variables.scss
    font-size: 3rem;
    line-height: 1.3em;
    font-weight: 300;
    opacity: 0.8;
}

.subscribe-overlay form {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 600px;
}

.subscribe-overlay .form-group {
    flex-grow: 1;
}

.subscribe-overlay .subscribe-email {
    display: block;
    padding: 14px 20px;
    width: 100%;
    border: none;
    color: var(--midgrey);
    font-size: 2rem;
    line-height: 1em;
    font-weight: normal;
    letter-spacing: 0.5px;
    user-select: text;
    border-radius: 8px;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}

.subscribe-email:focus {
    outline: 0;
    border-color: color(var(--lightgrey) l(-2%));
}


.subscribe-overlay-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
}

.subscribe-overlay-close:before {
    content: "";
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    opacity: 0.8;
    transform: rotate(45deg);
}

.subscribe-overlay-close:after {
    content: "";
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    opacity: 0.8;
    transform: rotate(-45deg);
}

.subscribe-overlay-close:hover {
    cursor: default;
}


/* -------------------------------- 

   Здарова! Это я Vudi, ты чо тут забыл?)

-------------------------------- */


    .post a:hover
    {
    border-bottom: 0px;
}
    code
    {
	color: #0070c9;
	background-color: rgba(244, 248, 251, 0.69);
}


/* Похожие посты */
/* ---------------------------------------------------------- */

.read-next-feed {
    display: flex;
    flex-wrap: wrap;
}

.read-next-card {
    position: relative;
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 25px;
    margin: 0 20px 40px;
    color: #fff;
    background: var(--darkgrey) center center;
    background-size: cover;
    border-radius: 5px;
    box-shadow: rgba(39,44,49,0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
}

.post-feed .post-card:first-of-type
{
	
	margin-left: 0px; // отступ у первого элемента
}

.post-feed .post-card:last-of-type
{
	
	margin-right: 0px; // отступ у последнего элемента
}

.post-feed .post-card:nth-child(3)
{
	
	margin-right: 0px; // отступ у 3его элемента
}

.post-feed .post-card:nth-child(4)
{
	
	margin-left: 0px; // отступ у 4его элемента
}

@media (min-width:650px) and (max-width:956px) {
.post-feed .post-card:nth-child(2)
{
	
	margin-right: 0px; // отступ у 2его элемента когда маленький экран
}

.post-feed .post-card:nth-child(3)
{
	
	margin-left: 0px; // отступ у 3его элемента когда маленький экран
	margin-right: 10px;
}
.post-feed .post-card:nth-child(4)
{
	
	margin-left: 10px; // отступ у 4его элемента когда маленький экран
	margin-right: 0px;
}
.post-feed .post-card:nth-child(5)
{
	
	margin-left: 0px; // отступ у 4его элемента когда маленький экран
}
}


.read-next-card:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: linear-gradient(135deg, rgba(0,40,60,0.8) 0%,rgba(0,20,40,0.7) 100%);
    border-radius: 5px;

    backdrop-filter: blur(2px);
}


/* страница поста */
/* ---------------------------------------------------------- */
.mainbar-list .mainbar-list-item,.sidebar-list .sidebar-list-item
{
	padding-bottom: 8px;
}

.container
{
	margin-left: auto;
	margin-right: auto;
	overflow-x: hidden;
	padding-left: 0;
	padding-right: 0;
	width: 100%;
}

.post-header
{
	margin-top: 10px;
}

.mainbar-list .mainbar-list-item,.sidebar-list .sidebar-list-item
{
	padding-bottom: 8px;
}
.post-meta
{
	align-items: center;
	display: flex;
	display: -ms-flexbox;
	display: -webkit-box;
	ms-flex-align: center;
	webkit-box-align: center;
	white-space: nowrap;
	border-bottom: 0px;
}


.text.is-uppercased
{
	letter-spacing: 1px;
	text-transform: uppercase;
}
.text.is-truncated
{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.text.is-uppercased
{
	text-transform: uppercase;
}

@media (min-width:356px)
{
	.container
	{
		max-width: 592px;
	}
	.post-meta
	{
	align-items: center;
	display: flex;
	display: -ms-flexbox;
	display: -webkit-box;
	ms-flex-align: center;
	webkit-box-align: center;
	white-space: nowrap;
	}

	
}
.post-meta
{
	align-items: center;
	display: flex;
	display: -ms-flexbox;
	display: -webkit-box;
	ms-flex-align: center;
	webkit-box-align: center;
	white-space: nowrap;
}
.post-content
{
	font-size: 15px;
	line-height: 22px;
}

.post-content center {
padding-bottom: 10px;
}

.post-content strong, b {
    font-weight: 500;
}

.post-content ol, ul {
    padding-left: 30px;
}

.post-meta
{
	margin-bottom: 17px;
}
.post-title
{
	margin-bottom: 11px;
}
.post-content h1,.post-content h2,.post-content h3,.post-content h4,.post-content h5,.post-content h6,.post-meta
{
	margin-bottom: 17px;
}
.post-content blockquote,.post-content p
{
	margin-bottom: 22px;
}
.post-content figure
{
	margin-bottom: 39px;
	margin-top: 28px;
}
.primary
{
	padding-bottom: 32px;
}
.footer-list .footer-list-item:nth-of-type(2),.post-card-extra,.related-list .related-list-item:nth-of-type(n+3),.sidebar-list .sidebar-list-item:nth-of-type(n+2)
{
	display: none;
}
@media (min-width:592px)
{
	.container
	{
		max-width: 728px;
	}
	.mainbar-list .mainbar-list-item,.sidebar-list .sidebar-list-item
	{
		flex: 0 0 50%;
		max-width: 50%;
		ms-flex: 0 0 50%;
		padding-bottom: 16px;
		webkit-box-flex: 0;
	}
	.sidebar-list .sidebar-list-item:nth-of-type(3)
	{
		display: block;
	}
	
	.box
	{
		border-radius: 2px;
	}
	.post-meta
	{
		margin-bottom: 18px;
	}
		.post-content
	{
		font-size: 15px;
		line-height: 24px;
	}
		.post-content h1,.post-content h2,.post-content h3,.post-content h4,.post-content h5,.post-content h6,.post-meta
	{
		margin-bottom: 18px;
	}
	.post-content blockquote,.post-content p
	{
		margin-bottom: 24px;
	}
	.post-content figure
	{
		margin-bottom: 42px;
		margin-top: 30px;
	}

}

.post-content h1
{
	font-size: 33px;
	line-height: 1.3;
}
.post-content h2
{
	font-size: 29px;
	line-height: 1.3;
}
.post-content h3
{
	font-size: 25px;
	line-height: 1.3;
}
.post-content h4
{
	font-size: 21px;
	line-height: 1.4;
}
.post-content h5
{
	font-size: 17px;
	line-height: 1.4;
}
.post-content h6
{
	font-size: 15px;
	line-height: 1.4;
}
.post-content figcaption
{
	color: #738a94;
	font-size: calc(1em - 2px);
	line-height: 1.5;
	margin-top: 1em;
}
.post-content h1,.post-content h2,.post-content h3,.post-content h4,.post-content h5,.post-content h6
{
	font-weight: 500;
	margin-top: 1em;
}
.post-content hr
{
	background-color: #e5eff5;
	border: none;
	height: 2px;
	margin-bottom: 2.5em;
	margin-top: 2.5em;
}
.post-content a
{
	color: $base-ssilki;
}
.post-content a:hover
{
	border-bottom: 0px;
}
.post-image
{
	width: 100%;
}
.post-title
{
	font-size: 31px;
	font-weight: 500;
	line-height: 1.3;
}

@media (max-width: 650px) {

	.post-title
	{
		font-size: 24px;
	}
	
	.post-content h1
	{
		font-size: 24px;
	}

	.post-content h2
	{
		font-size: 22px;
	}
	
}


.post-card-wrapper
{
	background: top/175% auto no-repeat;
	min-height: 140px;
}

@media (min-width:880px)
{
	.sidebar-list .sidebar-list-item
	{
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
		ms-flex: 0 0 33.33333%;
		webkit-box-flex: 0;
	}
	.sidebar-list .sidebar-list-item:nth-of-type(4)
	{
		display: block;
	}
}

@media (min-width:880px)
{
	.container
	{
		max-width: 952px;
	}
}


.grid,.sidebar-list,.mainbar-list
{
	display: flex;
	flex-wrap: wrap;
	margin-left: -8px;
	margin-right: -8px;
	ms-flex-wrap: wrap;
	margin-top: 3px;
}

.cell,.footer-list .footer-list-item,.mainbar,.mainbar-list .mainbar-list-item,.related-list .related-list-item,.sidebar,.sidebar-list .sidebar-list-item,.sidebar-list .sidebar-list-title
{
	flex: 0 0 100%;
	min-height: 1px;
	ms-flex: 0 0 100%;
	padding-left: 8px;
	padding-right: 8px;
	position: relative;
	webkit-box-flex: 0;
	width: 100%;
}

@media (min-width:880px)
{
	.mainbar-list .mainbar-list-item,.related-list .related-list-item
	{
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
		ms-flex: 0 0 33.33333%;
		webkit-box-flex: 0;
	}
	.mainbar-list.is-expandable .mainbar-list-item:first-of-type
	{
		flex: 0 0 100%;
		max-width: 100%;
		ms-flex: 0 0 100%;
		webkit-box-flex: 0;
	}
	.mainbar-list.is-expandable .mainbar-list-item:nth-of-type(5n+2),.mainbar-list.is-expandable .mainbar-list-item:nth-of-type(5n+3)
	{
		flex: 0 0 50%;
		max-width: 50%;
		ms-flex: 0 0 50%;
		webkit-box-flex: 0;
	}
	.related-list .related-list-item:nth-of-type(3)
	{
		display: block;
	}
}



@media (min-width:880px)
{
	.mainbar-list.is-expandable .post-card:first-of-type .post-card-excerpt,.mainbar-list.is-expandable .post-card:nth-of-type(5n+2) .post-card-excerpt,.mainbar-list.is-expandable .post-card:nth-of-type(5n+3) .post-card-excerpt,.navbar-misc,.navbar-nav .nav-title,.navbar-nav-toggle,.navbar-night-mode-toggle span,.navbar-social .social-list,.navbar-social .social-title
	{
		display: none;
	}

	.post-wrapper
	{
		padding: 5px 60px;
	}
	.post-wrapper .kg-width-full,.post-wrapper .kg-width-wide
	{
		margin-left: -60px;
		margin-right: -60px;
	}
	.post-wrapper .kg-width-full figcaption,.post-wrapper .kg-width-wide figcaption
	{
		padding-left: 60px;
		padding-right: 60px;
	}
	.post-content
	{
		font-size: 17px;
		line-height: 27px;
	}
	.post-title
	{
		margin-bottom: 14px;
	}
	.post-content h1,.post-content h2,.post-content h3,.post-content h4,.post-content h5,.post-content h6,.post-meta
	{
		margin-bottom: 20px;
	}
	.post-content blockquote,.post-content p
	{
		margin-bottom: 27px;
	}
	.post-content figure
	{
		margin-bottom: 47px;
		margin-top: 34px;
	}
	.mainbar-list.is-expandable .post-card:first-of-type .post-card-wrapper,.mainbar-list.is-expandable .post-card:nth-of-type(5n+2) .post-card-wrapper,.mainbar-list.is-expandable .post-card:nth-of-type(5n+3) .post-card-wrapper
	{
		background: center/cover no-repeat;
		box-shadow: inset 0 0 0 9999px rgba(0,89,147,.75);
		height: 100%;
	}
	.mainbar-list.is-expandable .post-card:nth-of-type(5n+2) .post-card-wrapper,.mainbar-list.is-expandable .post-card:nth-of-type(5n+3) .post-card-wrapper
	{
		min-height: 220px;
		padding: 32px 36px;
	}
	.mainbar-list.is-expandable .post-card:first-of-type .post-card-wrapper
	{
		min-height: 300px;
		padding: 40px 60px 60px;
	}
	.mainbar-list.is-expandable .post-card:first-of-type .mainbar-list.is-expandable .post-card:nth-of-type(5n+2) .mainbar-list.is-expandable .post-card:nth-of-type(5n+3) 
	{
		align-items: stretch;
		box-shadow: none;
		color: rgba(255,255,255,.75);
		font-size: 13px;
		min-height: auto;
		ms-flex-align: stretch;
		padding: 0;
		webkit-box-align: stretch;
	}
	.mainbar-list.is-expandable .post-card:nth-of-type(5n+2) .mainbar-list.is-expandable .post-card:nth-of-type(5n+3) 
	{
		margin-bottom: 8px;
	}
	.mainbar-list.is-expandable .post-card:first-of-type 
	{
		margin-bottom: 16px;
	}
	.mainbar-list.is-expandable .post-card:first-of-type .post-card-date,.mainbar-list.is-expandable .post-card:nth-of-type(5n+2) .post-card-date,.mainbar-list.is-expandable .post-card:nth-of-type(5n+3) .post-card-date
	{
		margin-left: 20px;
	}
	.mainbar-list.is-expandable .post-card:first-of-type .post-card-body,.mainbar-list.is-expandable .post-card:nth-of-type(5n+2) .post-card-body,.mainbar-list.is-expandable .post-card:nth-of-type(5n+3) .post-card-body
	{
		background-color: transparent;
		padding: 0;
		text-shadow: 0 1px 3px rgba(0,0,0,.5);
	}
	.mainbar-list.is-expandable .post-card:first-of-type .post-card-body
	{
		margin-right: 30%;
	}
	.mainbar-list.is-expandable .post-card:first-of-type .post-card-title,.mainbar-list.is-expandable .post-card:nth-of-type(5n+2) .post-card-title,.mainbar-list.is-expandable .post-card:nth-of-type(5n+3) .post-card-title
	{
		color: #fff;
	}
	.mainbar-list.is-expandable .post-card:nth-of-type(5n+2) .post-card-title,.mainbar-list.is-expandable .post-card:nth-of-type(5n+3) .post-card-title
	{
		font-size: 23px;
		margin-bottom: 20px;
	}
	.mainbar-list.is-expandable .post-card:first-of-type .post-card-title
	{
		font-size: 29px;
		margin-bottom: 36px;
	}
	.mainbar-list.is-expandable .post-card:first-of-type .post-card-extra
	{
		display: flex;
		display: -ms-flexbox;
		display: -webkit-box;
	}
}
.sidebar-list .sidebar-list-title
{
		display: none;
		padding-bottom: 12px;
}
.mainbar-list .mainbar-list-item.has-full-width
{
		flex: 0 0 100%;
		max-width: 100%;
		ms-flex: 0 0 100%;
		webkit-box-flex: 0;
}



@media (min-width:1168px)
{
	.container
	{
		max-width: 1168px;
	}
	.sidebar
	{
		flex: 0 0 25%;
		max-width: 25%;
		ms-flex: 0 0 25%;
		ms-flex-order: 13;
		order: 13;
		webkit-box-flex: 0;
		webkit-box-ordinal-group: 14;
	}
	.sidebar-list .sidebar-list-item
	{
		flex: 0 0 100%;
		max-width: 100%;
		ms-flex: 0 0 100%;
		webkit-box-flex: 0;
	}
	.footer-list .footer-list-item:nth-of-type(2),.related-list .related-list-item:nth-of-type(4),.sidebar-list .sidebar-list-item:nth-of-type(n),.sidebar-list .sidebar-list-title
	{
		display: block;
	}
	.sidebar-list .sidebar-list-item:not(:last-of-type)
	{
		padding-bottom: 12px;
	}
	.mainbar
	{
		flex: 0 0 75%;
		max-width: 75%;
		ms-flex: 0 0 75%;
		webkit-box-flex: 0;
	}
	.footer-list .footer-list-item,.related-list .related-list-item
	{
		flex: 0 0 25%;
		max-width: 25%;
		ms-flex: 0 0 25%;
		webkit-box-flex: 0;
	}
}



.banner.is-240x400
{
	height: 400px;
	width: 240px;
}
.banner.is-728x90
{
	height: auto;
	width: 100%;
}

.box
{
	background-color: #fff;
	display: block;
	height: 100%;
	overflow: hidden;
}
.box.has-x-padding-1
{
	padding-left: 4px;
	padding-right: 4px;
}
.box.has-y-padding-1
{
	padding-bottom: 4px;
	padding-top: 4px;
}
.box.has-x-paddingп2
{
	padding-left: 8px;
	padding-right: 8px;
}
.box.has-y-padding-2
{
	padding-bottom: 8px;
	padding-top: 8px;
}
.box.has-x-padding-3
{
	padding-left: 12px;
	padding-right: 12px;
}
.box.has-y-padding-3
{
	padding-bottom: 12px;
	padding-top: 12px;
}
.box.has-x-padding-4
{
	padding-left: 4px;
	padding-right: 16px;
}
.box.has-x-padding-5,.post-wrapper 
{
	padding-left: 20px;
	padding-right: 20px;
}

// увеличивает картинку по ширине блока
/*
.post-content center
{
	margin-left: -20px;
	margin-right: -20px;
}
*/
.box.has-y-padding-4
{
	padding-bottom: 16px;
	padding-top: 16px;
	border-bottom: 1px solid #e3e3e3;
}
.box.has-y-padding-5
{
	padding-bottom: 20px;
	padding-top: 20px;
}
a.box
{
	transition: box-shadow .2s;
	webkit-transition: box-shadow .2s;
}
a.box:hover
{
	transition: all 0.3s ease;
    transform: translate3D(0, -1px, 0);
	box-shadow: 0 2px 8px rgba(0,0,0,.08),0 0 1px rgba(0,0,0,.05);
    text-decoration: none;
}

.text.is-1
{
	font-size: 33px;
}
.text.is-2
{
	font-size: 29px;
}
.text.is-3
{
	font-size: 25px;
}
.text.is-4
{
	font-size: 21px;
}
.text.is-5
{
	font-size: 17px;
}
.text.is-6
{
    font-size: 15px;
    color: #15171a;
    font-weight: 500;
    letter-spacing: -.1px;
}
.text.is-7
{
	font-size: 13px;
}
.text.is-8
{
	font-size: 11px;
}
.text.is-primary
{
	color: #888;
}
.text.tags
{
	color: #404040;
}
.text.is-tags-v-posty
{
	color: #404040;
    font-weight: 500;
    letter-spacing: -.01em;
    line-height: 1.33341;
}

.text.is-tags
{
    color: #772f84;
}

.text.is-vajno
{
	color: #d037a0;
}

.text.is-truncated
{
	max-width: 100%;
	font-weight: 500;
}
.text.is-spaced
{
	margin-bottom: 1px;
}
.text.is-recommended
{
	font-size: 22px;
    font-weight: 500;
    color: #404040;
    line-height: 2;
}

// Archive style

.vipad-list
{
    float: left;
    display: flex;
    border: 0px;
    background-color: #f4f8fb;
}

.filter-bar
{
	width: 100%;
    height: auto;
    background-color: #f4f8fb;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    margin-top: 0;
    display: -ms-flexbox;
    display: flex;
}

.filter-bar-content
{
    margin: 0 auto;
    padding: 0 22px;
    position: relative;
    letter-spacing: -.016em;
    padding-top: 25px;
    padding-bottom: 25px;
}

.item
{
	display:none;
}

.item a
{
    border-bottom: 0px;
}

.item a:hover
{
    text-decoration: none;
    border-bottom: 0px;
}

.item-za-2-mes a
{
    border-bottom: 0px;
}

.item-za-2-mes a:hover
{
    text-decoration: none;
    border-bottom: 0px;
}

.post-content .items h4
{
    margin-top: 5px;
    margin-bottom: 5px;
}

.post-content .items-za-2-mes h4
{
    margin-top: 5px;
    margin-bottom: 5px;
}

.result-item {
    color: #333;
    display: inline-block;
    margin-top: 0;
    width: auto;
    min-width: 50%;
    position: relative;
}


.result-item-eyebrown {
    margin-left: 230px;
}

.result-item-eyebrown{
	font-size: 12px;
    line-height: 1.33341;
    font-weight: 500;
    letter-spacing: -.01em;
    margin-bottom: 2px;
    color: #888;
}

.result-item-img
{
	border: 1px solid #e3e3e3;
}

.result-item-img
{
	position: absolute;
    margin-right: 30px;
    display: inline-block;
    top: 0;
    left: 0;
}

.result-item-img .img
{
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 200px;
    height: 105px;
}

.result-item-img
{
	float: left;
}

.result-item-img figure
{
	display:block;
	margin: 0;
    padding: 0;
    
}

.result-item-content
{
	margin-left: 230px;
    max-width: 579px;
    display: inline-block;
    float: left;
}

.result-item-content-title
{
    margin-bottom: 8px;
    color: #15171a;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: -.1px;
    line-height: 1.4;
}

.paragraph {
    margin-top: 0;
}

.result-item-content-desc
{
    font-family: $primary-font; // variables inside partials > _variables.scss
    color: #738a94;
    font-size: 13px;
    line-height: 1.5;
}

.result-item-content h3
{
	margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
}

.result-item-eyebrown .category {
    padding-left: 5px;
    text-transform: uppercase;
    color: #772f84;
}

@media only screen and (max-width: 679px)
{

.result-item-eyebrown{
	    margin-left: 0px;
	    margin-bottom: 5px;
	    font-size: 11px;
		line-height: 1.36364;
		letter-spacing: -.008em;
}

.result-item-content
{
    max-width: calc(100% - 97px);
    margin-left: 0px;
}

.result-item-img
{
	margin-right: 15px;
	position: relative;
	
}

.result-item-img .img
{
    width: 80px;
    height: 42px;
}

.result-item-content-desc
{
	display: none;
}

}
@media only screen and (max-width: 1068px)
{
	.result-item {
		width: 100%;
	}
}

// about pages style

.row {
  clear: both;
  padding-top:20px;
}

.project-plitka .page-content .card-project img 
{
	padding: 0 0;
}

.mb4 {
margin-bottom: 4rem;
}

.max-width-4 {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0
}


.project-plitka .project-plitka .col, .col-right {
	box-sizing: border-box;
}

.projects-illustration {
	margin-bottom: -8rem;
}

.project-plitka .card-project-wrap.col {
	float: left;
	padding-top: 10px;
	padding-bottom:10px;
}

/* должно быть 50%, но почему то 42 */
.project-plitka .col-6 {
	width: 42%;
}

.mx-auto {
	margin-left: auto;
	text-align: center;
}

.project-plitka .mr-auto, .mx-auto {
	margin-right: auto;
}

.project-plitka .block {
	display: block;
}

.project-plitka .inline-block
{
	margin: 0 10px;
    font-size: 17px;
}

.project-plitka .text-decoration-none {
	text-decoration: none;
	border-bottom: 0px;
}

.project-plitka a:hover {
	text-decoration: none;
}

.p3 .text-small a {
	color: #404040;
}

.p3 .text-small a:hover {
	color: #0070c9;
}


.project-plitka .p3 {
	padding: 1rem;
}

.project-plitka .p3 h2 {
	padding-bottom: 2px;
}

.project-plitka .card-project h2 {
	font-size: 22px;
	line-height: 1.1;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}

.project-plitka .text-small {
	font-size: 14px;
}

.project-plitka .timeframe {
	color: #7c0e9e;
	font-size: 11px;
}

@media (max-width: 650px) {
	.project-plitka .card-project h2 {
		font-size: 18px;
	}
	.project-plitka .text-small {
		font-size: 12px;
	}
	.project-plitka .timeframe {
		font-size: 9px;
	}
}

.project-plitka .card-project p {
	margin-bottom: 0px !important;
}

.project-plitka .neg-letter-spacing {
	letter-spacing: -.04em;
}

.project-plitka .mb-sm {
	margin-bottom: .25rem;
}

.project-plitka .mt1 {
	margin-top: .5rem;
}

.project-plitka .text-default {
	font-size: 16px;
}

.project-plitka .text-color-navy-light {
	color: #506888;
}

.project-plitka .m0 {
	padding: 0 0.925rem;
    line-height: 1.35;
}



@media (max-width: 860px) {
    
.project-plitka {
    margin-top: 1rem;
    }
    
.project-plitka .projects-illustration {
        margin-top: 4rem;
        margin-bottom: -4rem;
        text-align: center
    }

    
    .project-plitka .inline-block
	{
		margin: 0 7px;
		font-size: 1.333333em;
	}
    
}

@media (max-width: 600px) {
.project-plitka .col {
        width: 100%;
    }
}
    
@media (min-width: 600px) {  
.project-plitka .card-project-right {
        padding-left: 10px;
    }
.project-plitka .card-project-left {
        padding-right: 10px;
    }    
}    


// страница яндекс икс

.table_blur {
  border-collapse: collapse;
  text-align: center;
}
@media (max-width: 360px) {
.table_blur {
    font-size: 13px;
}
}

.table_blur th {
  border: 1px solid #e3eef7;
  padding: 2px 7px;
  position: relative;
  font-weight: 500;
  vertical-align: middle;
}

.table_blur td {
  border: 1px solid #e3eef7;
  padding: 2px 7px;
  vertical-align : middle;
}

.table_blur td img {
  vertical-align : middle;
}

.okno-formi {
	font-weight: 400;
    padding: 10px 12px;
    min-height: 44px;
    color: #333333;
    border: solid 1px #efefef;
    border-radius: 3px;
    -webkit-appearance: none;
    transition: background 0.2s;
    width: calc(75% - 7px) !important;
    float: left;
    margin-right: 7px;
    font-size: 15px;
}

.knopka-formi {
    padding: 11px 15px;
    border-radius: 5px;
    cursor: pointer;
    outline: 0px;
    font-weight: 400;
    color: #fff;
    user-select: none;
    background: linear-gradient(to top, #3366cc, #3366cc);
    border-color: #3366cc0f;
    width: 25%;
    padding-left: 0px;
    padding-right: 0px;
    max-height: 44px;
    font-size: 15px;
}

@media (max-width: 390px) {
.okno-formi {
    font-size: 14px;
}
.knopka-formi {
    font-size: 14px;
}
}

// Диаграмма 3d

	@media (max-width: 766px) {
			.aktivnost3d {
				display: none;
			}
	}	


// Спиннер загрузки постов	
	
  .infinite-spinner {
		  display: block;
		  width: 40px;
		  height: 40px;
		  border-radius: 40px;
		  background-color: #333;
		  margin: 60px auto;
		  
		  -webkit-animation: scaleout 1.0s infinite ease-in-out;
		  animation: scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
  0% { -webkit-transform: scale(0.0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes scaleout {
  0% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

// подпись картинок

	.podpisimg {
	display: block;
    text-align: center;
    font-style: italic;
    font-size: 13px;
    margin-bottom: 5px;
    color: #666;
    }